.select-container {
  position: relative;
  display: inline-block;
}

.custom-select {
  width: 200px;
  padding: 10px;
  appearance: none; /* Removes default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.arrow-up,
.arrow-down {
  position: absolute;
  right: 10px;
  font-size: 12px;
  pointer-events: none; /* Makes the arrows non-interactive */
}

.arrow-up {
  top: 10px;
}

.arrow-down {
  bottom: 10px;
}
