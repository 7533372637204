@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
    font-family: "Montserrat", sans-serif;
}

html, body, #root {
  overflow: hidden;
}

#root {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.react-datepicker {
  padding: 31px 106px 41px 106px;
  font-family: 'Montserrat';
}

.react-datepicker__header {
  border-bottom: none;
  background-color: transparent;
}
.react-datepicker__current-month {
  font-size: 36px !important;
  color: #6f05ea;
  border-bottom: 0.5px solid #b7b7b7;
  padding-bottom: 20px;
}

.react-datepicker__week,
.react-datepicker__day-names {
  display: flex;
  gap: 50px;
}

.react-datepicker__day,
.react-datepicker__day-name {
  width: 50px;
  line-height: 4;
  height: 50px;
}
.react-datepicker__month {
  margin: 0px;
}
.react-datepicker__day-name {
  color: #6f05ea;
  font-size: 14px;
  font-weight: 600;
}
.react-datepicker__day {
  font-size: 14px;
  font-weight: 600;
}
.react-datepicker__day--outside-month {
  color: #b7b7b7;
}
.react-datepicker__day--selected {
  background-color: transparent;
  color: #6f05ea;
  position: relative;
  border: 1px solid #6f05ea;
}
.react-datepicker__day--selected:after {
  content: '.';
  font-size: 18px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.react-datepicker__day--selected:hover {
  background-color: transparent;
  color: #6f05ea;
}

.react-datepicker__navigation--previous {
  left: 106px;
}
.react-datepicker__navigation--next {
  right: 106px;
}
.react-datepicker__navigation {
  height: 23px;
  width: 14px;
  top: 51px;
}
.react-datepicker__navigation-icon::before {
  border-color: #6f05ea;
}
.react-datepicker__input-container {
  position: absolute;
  z-index: 10;
  top: 22px;
  opacity: 0;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 32px;
  cursor: pointer;
}
.react-datepicker__header__dropdown--scroll{
  width: 100px;
  position: absolute;
  top: 17px;
  left: 53%;
  height: 28px;
  opacity: 1;
}
.react-datepicker__year-read-view{
  width: 80px;
  height: 22px;
  opacity: 1;
}
.react-datepicker__year-read-view--down-arrow{
  display: none;
}
.selected-flag .flag{
    border-radius: 50%;
    height: 11px;
    width: 11px;
}
.selected-flag:hover{
    background: transparent !important;
}
.country-name{
    display: none;
}
.country-list{
    width: 85px !important;
}
.react-tel-input input:focus{
    border-bottom: 2px solid #6f05ea !important;
}
.react-tel-input input::placeholder {
    font-size : 13px;
    color:#A6A6A6;
    font-weight: 400;

}
.react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: 2px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
}
.country-city-select .MuiMenu-list{
  max-height: 240px;
  overflow-y: auto;
  scrollbar-width: none;
}
.recurrence-select .MuiMenu-list{
  max-height: 160px;
  overflow-y: auto;
  scrollbar-width: "201px";
}
.recurrence-select .MuiMenu-list::-webkit-scrollbar {
  width: 0px;
}
.country-city-select .MuiMenu-list::-webkit-scrollbar {
  width: 0px;
}
.list-block2 {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.list-block2::-webkit-scrollbar {
  display: none; 
}
.back-drop{
  max-width: 100vw;
  max-height: 100vh;
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
  .react-datepicker {
    padding: 21px 50px;
  }
  .react-datepicker__week,
  .react-datepicker__day-names {
    gap: 10px;
  }
  .react-datepicker__navigation {
    top: 10%;
  }
  .react-datepicker__header__dropdown--scroll{
    left: 55%;
  }
}
@media only screen and (max-width: 600px) and (min-width: 350px) {
  .react-datepicker {
    padding: 11px 0px;
  }
  .react-datepicker__week,
  .react-datepicker__day-names {
    gap: 0px;
  }
  .react-datepicker__current-month {
    font-size: 16px !important;
  }
  .react-datepicker__day-name {
    font-size: 12px;
  }
  .react-datepicker__day {
    font-size: 12px;
  }
  .react-datepicker__navigation {
    top: 19px;
    left: 21%;
  }
  .react-datepicker__header__dropdown--scroll{
    left: 53%;
    height: 20px;
  }
  .react-datepicker__year-select{
    width: 45px;
  }
  .react-datepicker__year-dropdown{
    width: unset;
    left: unset;
    padding: 0px 5px;
  }
}
@media only screen and (max-width: 600px){
  .react-datepicker__header__dropdown--scroll{
    left: 49%;
    top:9px;
    width: 0px;
  }
  .react-datepicker__year-select{
    width: 40px;
  }
  .react-datepicker__year-read-view{
    width: 40px;
    height: 15px;
  }
}
.dialog-btn-container{
  display:flex;
  justify-content: center;
  align-items:center;
}
.MuiTypography-h6.dialog-title{
  font-size: 26px;
  font-weight: 600;
  color: #000000;
}
.enter-prise-input-grid .MuiFormControl-root .MuiInputBase-root > input::placeholder{
  font-size: 13px;
}
.enter-prise-input-label{
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #334155 !important ;
}
@media (max-width: 600px){
  .MuiTypography-h6.dialog-title{
    font-size: 18px;
  }
  .enter-prise-input-label{
    font-size: 14px !important;
  }
}

.chat-upload-document-title{
  color:#6F05EA;
  font-size:20px !important;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight:700 !important;
}
@media (max-width: 600px){
  .chat-upload-document-title{
    font-size:16px !important;
  }
}
@media (max-width: 530px){
  .chat-upload-document-title{
    font-size:16px !important;
  }
}
@media (max-width: 430px){
  .chat-upload-document-title{
    font-size:14px !important;
  }
}
@media (max-width: 400px){
  .chat-upload-document-title{
    font-size:14px !important;
  }
}

@-moz-document url-prefix() {
  .rbc-time-content {
    scrollbar-color: #6F05EA #CBD5E1;
    padding-right: '5px'; 
  }
}

@supports (-moz-appearance:none) {
  .rbc-time-content {
    scrollbar-color: #6F05EA #CBD5E1;
    padding-right: '5px';  
  }
}

.customer-user-profile * {
  user-select: none !important;
}

.termConditionModelDescription::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}
.termConditionModelDescription::-webkit-scrollbar-thumb {
  background: #6F05EA;
  border-radius: 4px;
  width: 6px;
}
.termConditionModelDescription::-webkit-scrollbar-track {
  background: #CBD5E1;
  border-radius: 6px;
}

#menu-allowedPet ul::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}
#menu-allowedPet ul::-webkit-scrollbar-thumb {
  background: #6F05EA;
  border-radius: 4px;
  width: 6px;
}
#menu-allowedPet ul::-webkit-scrollbar-track {
  background: #CBD5E1;
  border-radius: 6px;
}
#menu-allowedPet ul{
  max-height: 200px;
  overflow-y: scroll;
}

/* GLOBAL CSS FOR SCROLLBAR */
::-webkit-scrollbar {
  width: 6px !important;
  border-radius: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: #CBD5E1 !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: #6F05EA !important;
  border-radius: 4px !important;
  width: 6px !important;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #6F05EA !important;
}
.react-datepicker__year-option--selected{
  display: none;
}
.react-datepicker__year-option:last-of-type{
  display: none;
}
.react-datepicker__year-dropdown{
  padding: 0px 10px;
}
.react-datepicker__year-option{
 color: #6f05ea;
 line-height: 22px;
 font-weight: 500;
}
.react-datepicker__year-option:hover{
  background-color: transparent;
}
.no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  overflow-y: hidden;
  background-image: none;
}

.no-arrow::-ms-expand {
  display: none;
}

.no-arrow:focus {
  height: auto;
  overflow-y: auto;
  max-height: 200px;
}

.customer-phone-input::placeholder {
  color: '#A6A6A6' !important;
  font-size: '8px' !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  font-family: 'Montserrat' !important;
}

.MuiAutocomplete-option[aria-selected="true"]{
  background-color: #6F05EA1A !important 
}